import Layout from "../components/layout";
import Seo from "../components/seo";
import { Link } from "gatsby";
import React from "react";
import useStations from "../utils/hooks/useStations";

const WeatherToolsPage = () => {
  const h1Text = "NEWA | Weather and Data Summary Tools";
  const { station } = useStations();

  return (
    <Layout station={station} h1Text={h1Text}>
      <Seo title="Weather Tools" />

      <div className="container px-3 mx-auto container-article xl:pt-6 lg:px-4 xl:px-12">
        <div className="grid grid-cols-1 gap-6 mb-8 lg:mb-12 lg:grid-flow-col xl:gap-12 md:grid-cols-12">
          <div className="md:col-span-8">
            <Link to="/all-weather-data-query">
              <h2>All Weather Data Query</h2>
            </Link>
            <p>
              Display or download hourly weather data, daily summaries, or
              degree days in tabular or graphical format. Select the location,
              date range, weather variables, type of data, and output format
              desired.
            </p>
            <Link to="/degree-day-calculator">
              <h2>Degree Day Calculator</h2>
            </Link>
            <p>
              Get degree day results for all the base temperatures used in NEWA
              tools using physical weather station data. Select the location,
              date range, base temperature, and formula to get the accumulated
              degree days and a chart plotting degree day accumulations over
              time. This tool focuses on recent data and includes the 5-day
              forecast.
            </p>
            <p>
              If you don't find a nearby NEWA station, try this{" "}
              <a
                href="http://climatesmartfarming.org/tools/csf-growing-degree-day-calculator/"
                target="blank"
              >
                Growing Degree Day (GDD) Calculator
              </a>{" "}
              provided by Cornell University partner program{" "}
              <a href="http://climatesmartfarming.org/" target="blank">
                {" "}
                Climate Smart Farming
              </a>{" "}
              (CSF). The CSF GDD calculator uses interpolated (estimated)
              temperature data, instead of on-farm microclimate data, to provide
              current season accumulation with historical climate comparisons.
            </p>
            <hr></hr>
            <h2>Other Weather Tools</h2>
            <h3>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://radar.weather.gov/"
              >
                NWS National Doppler Radar Sites
              </a>
            </h3>
            <p>
              Doppler radar sites are available on this map generated by the
              NWS. Click on the Doppler radar dot nearest to your location of
              interest.
            </p>
             
            <h3>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.nrcc.cornell.edu/"
              >
                Northeast Regional Climate Center
              </a>{" "}
            </h3>
            <p>
              Weather data, weather analyses, climate resources, education and
              research, resources for industry, publications and services for
              each state and the Northeast region.
            </p>
             
            <h3>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://sercc.com/"
              >
                Southeast Regional Climate Center
              </a>{" "}
            </h3>
            <p>
              Weather data, weather analyses, climate resources, education and
              research, resources for industry, publications and services for
              each state and the Southeast region.
            </p>
             
            <h3>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://mrcc.purdue.edu"
              >
                Midwest Regional Climate Center
              </a>{" "}
            </h3>
            <p>
              Weather data, weather analyses, climate resources, education and
              research, resources for industry, publications and services for
              each state and the Midwest region.
            </p>
             
            <h3>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.srcc.lsu.edu/"
              >
                Southern Regional Climate Center
              </a>{" "}
            </h3>
            <p>
              Weather data, weather analyses, climate resources, education and
              research, resources for industry, publications and services for
              each state and the Southern region.
            </p>
             
            <h3>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://wrcc.dri.edu/"
              >
                Western Regional Climate Center
              </a>{" "}
            </h3>
            <p>
              Weather data, weather analyses, climate resources, education and
              research, resources for industry, publications and services for
              each state and the Western region.
            </p>
             
            <h3>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://hprcc.unl.edu/"
              >
                High Plains Regional Climate Center
              </a>{" "}
            </h3>
            <p>
              Weather data, weather analyses, climate resources, education and
              research, resources for industry, publications and services for
              each state and the High Plains region.
            </p>
             
            <h3>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.nrcc.cornell.edu/dyn_images/grass/soilTemp.png"
              >
                Two-inch Soil Temperature Estimates
              </a>{" "}
            </h3>
            <p>
              Hosted by the Northeast Regional Climate Center, this map shows
              the estimated soil temperature in the Northeast region.
            </p>
             
            <h3>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.nysmesonet.org/"
              >
                New York State Mesonet
              </a>{" "}
            </h3>
            <p>
              Hosted by the University at Albany, this collection of 126 weather
              stations across New York State provides weather data from every
              county and borough. Data includes temperature, humidity, wind
              speed and direction, pressure, solar radiation, snow depth, and
              soil information. Ten stations send data to NEWA.
            </p>
             
            <h3>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.njweather.org/"
              >
                New Jersey Weather Network
              </a>{" "}
            </h3>
            <p>
              Hosted by Rutgers New Jersey Agricultural Experiment Station and
              brought to you by the Office of the NJ State Climatologist, this
              website provides tabular data, maps, charts, and analyses of the
              weather and climate of NJ. All their weather stations send data to
              NEWA.
            </p>
             
            <h3>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.weather.gov/"
              >
                NWS Home
              </a>
            </h3>
            <p>
              On the home page of the National Weather Service (NWS), you can
              find, at a glance, watches, warnings, and advisories, as well as
              access to many other weather resources.
            </p>
             
            <h3>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.cpc.ncep.noaa.gov/products/predictions/610day/"
              >
                NWS 6-10 Day Outlook Maps
              </a>
            </h3>
            <p>
              This site provides temperature and precipitation generated by the
              National Weather Service (NWS) Climate Prediction Center and shows
              the percent probability for normal, above normal or below normal
              conditions.
            </p>
             
            <h3>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://forecast.weather.gov/MapClick.php?lat=42.8664&amp;lon=-76.9822#.XtkwxvlKiUk"
              >
                NWS Detailed Forecasts 
              </a>
            </h3>
            <p>
              Find your National Weather Service (NWS) forecast on this page
              (example is for Geneva, NY). Enter your location in the upper
              left-hand corner box. Interact with this tool to view current
              conditions, 4-day forecast details, and a map to adjust the
              specific forecast location.
            </p>
             
            <h3>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://graphical.weather.gov/"
              >
                NWS Graphical Forecasts
              </a>
            </h3>
            <p>
              These National Weather Service (NWS) forecasts provide up-to-date
              information about temperature, dewpoint, wind speed, etc, for the
              US, state or region, as well as access to several other products
              (e.g. satellite, radar, air quality).
            </p>
             
            <h3>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.cpc.ncep.noaa.gov/products/monitoring_and_data/drought.shtml"
              >
                NWS Drought Monitoring
              </a>
            </h3>
            <p>
              Hosted by the National Weather Service (NWS) and generated in
              graphical and tabular formats by the Climate Prediction Center,
              this page provides access to several drought monitoring products
              and historical records that use the Palmer Drought Severity Index
              (PDSI) and Crop Moisture Index (CMI).
            </p>
             
            <h3>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.cpc.ncep.noaa.gov/products/expert_assessment/mdo_summary.php"
              >
                NWS US Monthly Drought Outlook
              </a>
            </h3>
            <p>
              Developed by the NOAA Climate Prediction Center and hosted by the
              National Weather Service (NWS), this page depicts and documents
              current large-scale tends of drought based on statistical and
              dynamical forecasts.
            </p>
             
            <h3>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.usda.gov/sites/default/files/documents/wwcb.pdf"
              >
                USDA Weekly Weather & Crop Bulletin
              </a>
            </h3>
            <p>
              Jointly prepared by the U.S. Department of Commerce, NOAA, and the
              USDA, the WWCB provides information on weather, climate and
              agricultural developments worldwide, along with detailed charts
              and tables of agrometeorological information. You can subscribe to
              receive the bulletin via email notification.
            </p>
            <hr></hr>
            <h2>Weather Stations</h2>
            <h3>
              {" "}
              <Link to="/about-weather-stations">
                About Weather Stations
              </Link>{" "}
            </h3>
            <p>
              Everything you need to know about NEWA’s weather stations. NEWA
              works with{" "}
              <Link to="https://www.onsetcomp.com/corporate/partners/newa">
                Onset Data Loggers
              </Link>
              ,and{" "}
              <Link to="https://kestrelmet.com/kestrelmet-6000-ag-weather-station-newa">
                KestrelMet
              </Link>{" "}
              configured for NEWA’s tools. NEWA also collects data from various
              partner state mesonets (independent networks of stations) and
              airports.
            </p>
            <h3>
              <Link to="/buy-a-weather-station">Buy a Weather Station</Link>{" "}
            </h3>
            <p>
              Visit this page to contact vendors and understand the steps
              required for connecting with NEWA resources.
            </p>
            <h3>
              <Link to="/placement-guide">Placement Guide</Link>{" "}
            </h3>
            <p>
              If you recently purchased a weather station, visit this page to
              learn more about collecting accurate and unobstructed sensor data
              on your farm by correctly placing the weather station on your
              site.
            </p>
            <h3>
              <Link to="/maintenance-guide">Maintenance Guide</Link>{" "}
            </h3>
            <p>
              Maintaining your weather station is key. Visit this page to learn
              how to keep the sensors and your weather station working properly.
            </p>
            <footer className="pt-6 mt-12 text-base border-t border-gray-200">
              <p>
                Page updated September 2020 by J. Carroll, K. Eggleston, and D.
                Olmstead
              </p>
            </footer>
          </div>
          <div className="pt-4 text-base text-gray-700 border-t-2 border-gray-200 md:col-span-4 md:border-l-2 md:border-t-0 md:pt-3 md:pl-4 xl:pl-8">
            <h2>Get a Weather Station</h2>
            <p>
              NEWA is compatible with special configurations of{" "}
              <Link to="https://www.onsetcomp.com/corporate/partners/newa">
                Onset
              </Link>{" "}
              and{" "}
              <Link to="https://kestrelmet.com/kestrelmet-6000-ag-weather-station-newa">
                KestrelMet
              </Link>{" "}
              weather instruments. See what your options are.
            </p>
            <p>
              <Link
                className="mt-3 rounded-none btn btn-secondary-reverse btn-large"
                aria-label="Buy a Weather Stations"
                to="/buy-a-weather-station"
              >
                Buy a Weather Station
              </Link>
            </p>

            <hr></hr>

            <h2>Have a question?</h2>
            <p>
              Reach out to our support center and ask questions to get support
              for NEWA tools, resources, and weather stations.
            </p>
            <p>
              <a
                className="mt-3 rounded-none btn btn-secondary-reverse btn-large"
                href="mailto:support@newa.zendesk.com"
              >
                Contact the NEWA Help Desk
              </a>
            </p>

            <hr></hr>

            <h2>More Info on NEWA Tools</h2>

            <h3>
              <Link to="/about-degree-days">About Degree Days</Link>
            </h3>
            <p>
              This page gives background information on how degree days are
              calculated, their importance in biology, and what IPM tools use
              them.
            </p>

            <h3>
              <Link to="/how-newa-handles-weather-data">
                How NEWA Handles Weather Data
              </Link>
            </h3>
            <p>
              Learn about how weather data is kept accurate and up-to-date and
              how variables are uniquely handled to reflect agricultural
              settings.
            </p>

            <hr></hr>

            <h2>Become a Partner</h2>
            <p>
              NEWA is a partnership of land grant universities and grower
              associations. If you live in a partner state, you can{" "}
              <Link to="/buy-a-weather-station">buy a weather station</Link> for
              your farm and connect to NEWA.
            </p>
            <p>
              <Link
                className="mt-3 rounded-none btn btn-secondary-reverse btn-large"
                aria-label="Learn More About Becoming a Partner"
                to="/become-partner"
              >
                Learn More
              </Link>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default WeatherToolsPage;
